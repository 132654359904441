<template>
    <div>
        <!-- 顶部信息栏 -->
        <div class="top gray">
            <div id="homeTitle" class="font18">{{title}}</div>
        </div>
        <div class="appnav">
            <div class="appleft">
                <div class="window-echarts-open-btn" @click="windowEchartsBtnClick($event)">
                    <div class="appnav4"></div>
                </div>
                <div class="appnav1" @click="navClick"><span>主页</span></div>
                <div class="appnav1 selected"><span>智慧化健身房</span></div>
                <div class="appnav1" @click="navClick"><span>体育场馆</span></div>
                <div class="appnav1" @click="navClick"><span>游步道</span></div>
            </div>
            <div class="appright">
                <div class="appnav2" @click="navClick"><span>云健身房</span></div>
                <div class="appnav2" @click="navClick"><span>赛事</span></div>
                <div class="appnav2" @click="navClick"><span>体育产业</span></div>
                <div class="appnav2" @click="navClick"><span>体育协会</span></div>
                <div class="window-big-btn open" @click="fullScreenClick($event)">
                    <div class="appnav4"></div>
                </div>
            </div>
        </div>
        <div class="time">
            <span>{{titleDate}}</span>
            <span>{{titleWeek}}</span>
            <span>{{titleTime}}</span>
        </div>
        <div class="quit" @click="quitShow = true">退出</div>
        <div class="quitTip" style="z-index:10" v-if="quitShow">
            <p>提示</p>
            <p style="font-size:0.16rem;">确定退出？</p>
            <div style="display: flex;justify-content: space-around;">
                <div class="quitCofirm" @click="quit">确定</div>
                <div class="quitCancle" @click="quitShow = false">取消</div>
            </div>
        </div>
        <div class="goBack">
            <div @click="backHome" style="cursor: pointer;">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>{{leftTop}}</span>
        </div>
        <div class="chartContent">
            <div>
                <div class="c-l-t sportnav darkBack">
                    <div>活跃时间段分布</div>
                    <div id="activeTime"></div>
                </div>
                <div class="c-l-b darkBack sportnav">
                    <div>活跃星期分布</div>
                    <div id="week"></div>
                </div>
            </div>
            <div>
                <div class="c-c-t darkBack sportnav"
                    style="display: flex;justify-content: space-around;text-align: center;align-items: flex-end;padding-bottom: 0.65rem;">
                    <div>
                        <div>当月客流量</div>
                        <div class="imgCircle smallcircle">{{traffic.month}}人</div>
                    </div>
                    <div>
                        <div>今日客流量</div>
                        <div class="imgCircle bigCircle">{{traffic.day}}人</div>
                    </div>
                    <div>
                        <div>今年客流量</div>
                        <div class="imgCircle smallcircle">{{traffic.year}}人</div>
                    </div>
                </div>
                <div class="c-c-b darkBack sportnav">
                    <div>活跃月份分布</div>
                    <div id="month"></div>
                </div>
            </div>
            <div class="content-right darkBack">
                <div class="baixingnav">
                    <div style="font-size: 0.2rem;">客流量排名</div>
                    <div>
                        <span class="selecte1" @click="selectedhandle1">月</span>
                        <span class="selecte1" @click="selectedhandle1">年</span>
                        <span class="selecte1" @click="selectedhandle1">总</span>
                    </div>
                </div>
                <div>
                    <vueSeamlessScroll :data="list" class="seamlessWarp" :class-option="classOption">
                        <table id="table">
                            <tr v-for="(item,index) in list" :key="index" @click="asspopoutshow(item.id)">
                                <td style="cursor:pointer;width:10%;" v-if="index == 0">
                                    <div class="first">1</div>
                                </td>
                                <td style="cursor:pointer;width:10%;" v-else-if="index == 1">
                                    <div class="second">2</div>
                                </td>
                                <td style="cursor:pointer;width:10%;" v-else-if="index == 2">
                                    <div class="third">3</div>
                                </td>
                                <td style="cursor:pointer;width:10%;" v-else>{{index+1}}</td>
                                <td style="cursor:pointer;width:60%;">{{item.title}}</td>
                                <td style="width:30%;">{{item.traffic}}</td>
                            </tr>
                            <tr v-if="list == null">
                                <td>暂无数据</td>
                            </tr>
                        </table>
                    </vueSeamlessScroll>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getAjax, timestamp} from "../../assets/js/websocket";

	import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        components:{
			vueSeamlessScroll,
		},
        data() {
            return {
                leftTop:"",
                venueId:"",
                // 标题
                title: window.PageTitle,
                // 标题时间
                titleTime: "",
                // 标题日期
                titleDate: "",
                // 标题周
                titleWeek: "",
                quitShow: false,
                area: "",
                sport: {
                    data: [0, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                    number: [100, 200, 300, 1400, 500, 600, 100, 200, 300, 1400, 500, 600, 100, 1200, 300, 400, 500, 600, 300]
                },
                weekData: {
                    data: ['1', '2', '3', '4', '5', '6', '7'],
                    number: [120, 200, 150, 80, 70, 110, 130],
                    color1: "#7EA7E2 ",
                    color2: "#EAAE3140",     // 网格线的颜色
                    color3: "#76B795",     // 渐变初始的颜色
                    color4: "#8AD57D",     // 渐变末尾颜色
                },
                monthData: {
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    number: [120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70],
                    color1: "#7EA7E2",
                    color2: "#EAAE3140",     // 网格线的颜色
                    color3: "#E5813A ",     // 渐变初始的颜色
                    color4: "#EFDC29 ",     // 渐变末尾颜色
                },
                list:[],
                traffic:{
                    day:"0",
                    month:"0",
                    year:"0"
                },
                trafficTimer:""
            }
        },
        computed: {
		    classOption () {
		        return {
					step: 0.4, // 数值越大速度滚动越快
					// limitMoveNum: this.member.membersInfo.length, // 开始无缝滚动的数据量 this.dataList.length
					limitMoveNum: 19, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
		        }
		    }
		},
        methods: {
            backHome() {
                this.$router.push({
                    path: "/home?content=智慧化健身房"
                    // params: {
                    //     content: e.target.innerText,
                    // }
                });
            },
            // 标题栏时间
            getDate() {
                var that = this;
                setInterval(function () {
                    var shwoDate = window.showDate();
                    that.titleTime = shwoDate.titleTime
                    that.titleWeek = shwoDate.titleWeek
                    that.titleDate = shwoDate.titleDate
                }, 1000)
            },
            logOut() {
                let screenUserInfo = JSON.parse(window.localStorage.getItem("screenUserInfo"))
                let data = {
                    token: window.getToken()
                }
                getAjax({
                    url: "/map/screen/logout?token=" + screenUserInfo.token,
                    method: "GET",
                    data: data,
                    success: function (res) {
                        // console.log(res)
                    },
                })
            },
            // 退出
            quit() {
                let that = this;
                // 清除用户信息
                that.logOut()
                window.localStorage.removeItem("screenUserInfo");
                // this.$cookies.remove("screenUserInfo")
                that.$router.push('/');
                that.quitShow = false;
                location.reload();
            },
            // 导航栏点击事件
            navClick(e) {
                // console.log(e.target.innerText)
                if (e.target.innerText == "体育场馆") {
                    this.$router.push("/venues");
                } else {
                    this.$router.push({
                        path: "/home?content=" + e.target.innerText,
                        // params: {
                        //     content: e.target.innerText,
                        // }
                    });
                }

            },
            // 全屏
            fullScreenClick(event) {
                window.fullScreenClick(event)
            },
            // 显示隐藏
            windowEchartsBtnClick(event) {
                window.echartsBtnClick(event);
            },
            lineOption(id, number) {
                // let canye = this.$echarts.init(document.getElementById(id));
                let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
                if (canye == null) { // 如果不存在，就进行初始化
                    canye = this.$echarts.init(document.getElementById(id));
                }
                var option = {
                    xAxis: {
                        type: 'category',
                        axisTick: {
                            show: false
                        },
                        axisLine: {              // y轴左侧的样式
                            show: true,
                            lineStyle: {
                                color: '#2D94D0',
                            }
                        },
                        axisLabel: {               // x轴字体的颜色
                            textStyle: {
                                show: true,
                                fontFamily: '微软雅黑',
                                color: "#7EA7E299",
                                fontSize: '13',
                            },
                        },
                        offset: 4.5,      // x轴字体与x轴的距离
                        data: number.data,
                    },
                    grid: {
                        left: "10%",
                        right: "8%",
                        top: "15%",
                        bottom: "10%"
                    },
                    yAxis: {
                        type: 'value',
                        splitNumber: 4,         // y轴左侧显示的条数
                        splitLine: {            // 网格线的颜色
                            show: true,
                            lineStyle: {
                                color: ['#2D94D040'],
                                width: 1,
                                type: 'solid',
                            }
                        },
                        axisLabel: {               // y轴字体的颜色
                            textStyle: {
                                show: true,
                                fontFamily: '微软雅黑',
                                color: "#7EA7E299",
                                fontSize: '0.14rem',
                            },
                        },
                        // axisLabel: {        // 隐藏y轴数据
                        //     formatter: function () {
                        //         return "";
                        //     }
                        // },
                    },
                    series: [{
                        data: number.number,
                        symbol: 'circle',//拐点样式
                        symbolSize: 9,   //拐点圆的大小
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: '#20D7FE', //改变折线点的颜色
                                lineStyle: {
                                    color: '#6AD4F9' //改变折线颜色
                                }
                            }
                        },
                    }]
                };
                canye.setOption(option);
                // 图表自动缩放
                window.addEventListener("resize", function () {
                    canye.resize();
                });
            },
            // 柱状图
            fitness(id, number) {
                let that = this;
                // let canye = that.$echarts.init(document.getElementById(id));
                let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
                if (canye == null) { // 如果不存在，就进行初始化
                    canye = this.$echarts.init(document.getElementById(id));
                }
                // var data = number.number
                // var data = [];
                // for (let i = 0; i < 17; ++i) {
                //     data.push(Math.round(Math.random() * 200));
                // }

                let option = {
                    xAxis: {
                        type: 'category',
                        offset: 5,          // x轴 字与轴的距离
                        axisLine: {              // y轴左侧的样式
                            show: true,
                            lineStyle: {
                                color: number.color1,
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                show: true,
                                fontSize: '0.13rem'
                            }
                        },
                        axisTick: {                 // y轴刻度线
                            show: false,
                            alignWithLabel: true,
                        },
                        data: number.data
                    },
                    grid: {
                        left: "8%",
                        right: "8%",
                        top: "15%",
                        bottom: "18%"
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {            // 网格线的颜色
                            show: true,
                            splitNumber: 5,
                            lineStyle: {
                                color: number.color2,
                                width: 1,
                                type: 'solid',
                            },
                        },
                        axisLabel: {               // y轴字体的颜色
                            textStyle: {
                                show: true,
                                fontFamily: '微软雅黑',
                                color: "#7EA7E299",
                                fontSize: '0.13rem',
                            },
                        },
                    },
                    series: [{
                        data: number.number,
                        type: 'bar',
                        barWidth: '40%',              // 柱子的宽度
                        itemStyle: {
                            normal: {
                                color: this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: number.color3 // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: number.color4 // 100% 处的颜色
                                }], false)
                            }
                        },
                    }]
                };;
                canye.setOption(option);
                // }
                //图表自动缩放
                window.addEventListener("resize", function () {
                    canye.resize();
                });
            },
            // 切换时间
            selectedhandle1(e) {
                let that = this;
                $('.selecte1').css({ 'background-color': '#00000000', 'color': '#838181' })
                e.target.style.background = "#7EA7E2";
                e.target.style.color = "#000000";
                // console.log(e.target.innerText)
                that.getTrafficRank(e.target.innerText)
            },
            // 获取活跃时间段
            getTime(){
                let that = this;
                let data ;
                // console.log(that.venueId)
                let url = window.appHost+"/api/v1/screen/Venue/active_time?businessid="+that.venueId
                let result = window.ajaxRequest(url,JSON.stringify(data),"post")
                // console.log(result)
                that.sport.number = []
                for(let i in result.data){
                    // console.log(result.data[i])
                    that.sport.number.push(result.data[i])
                }
                this.lineOption("activeTime", this.sport)
            },
            // 获取活跃星期
            getWeek(){
                let that = this;
                let data;
                let url = window.appHost+"/api/v1/screen/Cloud_Gym/active_week?businessid="+that.venueId
                let result = window.ajaxRequest(url,JSON.stringify(data))
                // console.log(result)
                that.weekData.data = [];
                that.weekData.number = [];
                result.data.forEach((item,index)=>{
                    that.weekData.data.push(item.num)
                    that.weekData.number.push(item.week)
                })

                this.fitness("week", this.weekData)
            },
            // 获取活跃月份
            getMonth(){
                let that = this;
                let data;
                let url = window.appHost+"/api/v1/screen/Venue/active_month?businessid="+that.venueId
                let result = window.ajaxRequest(url,JSON.stringify(data))
                // console.log(result)
                if(result.code == 1){
                    that.monthData.number = [];
                    result.data.forEach((item,index)=>{
                        that.monthData.number.push(item.num)
                    })
                }
                
                this.fitness("month", this.monthData)

            },
            // 获取客流量
            getTraffic(){
                let that = this;
                let data;
                let url = window.appHost+"/api/v1/screen/Venue/totalCount?venue_id="+that.venueId
                let result = window.ajaxRequest(url,JSON.stringify(data))
                // console.log(result)
                if(result.code == 1){
                    that.traffic.day = result.data.dayCount;
                    that.traffic.month = result.data.monthCount;
                    that.traffic.year = result.data.yearCount;
                }else{
                    that.traffic.day = 0;
                    that.traffic.month = 0;
                    that.traffic.year = 0;
                }
                
            },
            // 获取客流量排名
            getTrafficRank(type){
                let that = this;
                let url;
                if(type == "总") url = window.appHost+"/api/v1/screen/Venue/durationRankingDate"
                else if (type == "月") url = window.appHost+"/api/v1/screen/Venue/durationRankingDate?type=month"
                else  url = window.appHost+"/api/v1/screen/Venue/durationRankingDate?type=year"
                let data;
                let result = window.ajaxRequest(url,JSON.stringify(data))
                // console.log(result)
                that.list = result.data;
            }

        },
        mounted() {
            // console.log(this.$route.query)
            this.leftTop = this.$route.query.title;
            this.venueId = this.$route.query.id;
            let screenUserInfo = JSON.parse(window.localStorage.getItem("screenUserInfo"))
            // console.log(screenUserInfo)
            this.area = screenUserInfo.name
            window.windowResize()
            this.getDate()
            // this.lineOption("activeTime", this.sport)
            this.getTime();
            this.getWeek();
            this.getMonth();
            this.getTraffic();
            this.getTrafficRank("总");
            this.trafficTimer = setInterval(() => {
                this.getTraffic();
            }, 300000);
            // this.fitness("week", this.weekData)
            // this.fitness("month", this.monthData)
            
        },
        beforeDestroy () {
            clearInterval(this.trafficTimer)
        }
    }
</script>

<style scoped rel="stylesheet/less">
    @import url("../../assets/css/index.css");
</style>
<style>
    .imgCircle {
        background-image: url('../../../public/images/circle.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .goBack>img {
        width: 0.12rem;
        height: 0.1rem;
        margin: 0.12rem;
        cursor: pointer;
    }

    .chartContent {
        color: #ffffff;
        position: relative;
        top: 0.3rem;
        width: 94%;
        left: 3%;
        display: flex;
        justify-content: space-between;
    }

    .c-l-t {
        width: 5.96rem;
        height: 3.85rem;
    }

    .c-l-b {
        height: 3.93rem;
        width: 5.96rem;
        margin-top: 0.2rem;
    }

    #activeTime {
        width: 5.4rem;
        height: 3.2rem;
    }

    #week {
        width: 5rem;
        height: 3.4rem;
    }

    .c-c-t {
        width: 7rem;
        height: 3.85rem;
    }

    .c-c-b {
        width: 7rem;
        height: 3.93rem;
        margin-top: 0.2rem;
    }

    #month {
        width: 6.5rem;
        height: 3.4rem;
    }

    .sportnav {
        color: #FFFFFF;
        padding: 0.2rem;
        font-size: 0.2rem;
        position: relative;
    }

    .content-right {
        width: 5rem;
        height: 7.98rem;
        padding: 0.2rem;
    }

    .smallcircle {
        width: 1.4rem;
        height: 1.4rem;
        margin-top: 0.2rem;
        line-height: 1.4rem;
        color: #7EA7E2;
    }

    .bigCircle {
        width: 2rem;
        height: 2rem;
        margin-top: 0.2rem;
        line-height: 2rem;
        font-size: 0.28rem;
        color: #7EA7E2;
    }

    .baixingnav {
        display: flex;
        justify-content: space-between;
    }

    .selecte1 {
        width: 33%;
        text-align: center;
        height: 0.22rem;
        line-height: 0.22rem;
        display: inline-block;
        font-size: 0.12rem;
        cursor: pointer;
        color: #838181;
    }

    .selecte1:nth-child(1) {
        /* background-color:#7EA7E2; */
        border-radius: 0.11rem 0rem 0rem 0.11rem;
        /* color:#000000; */
    }

    .selecte1:nth-child(2) {
        /* background:red; */
        border-left: 1px solid #838181;
        border-right: 1px solid #838181;
    }

    .selecte1:nth-child(3) {
        /* background:red; */
        background-color: #7EA7E2;
        color: #000000;
        border-radius: 0rem 0.11rem 0.11rem 0rem;
    }


    .baixingnav>div:nth-child(2) {
        display: flex;
        justify-content: space-around;
        width: 1.05rem;
        height: 0.22rem;
        border: 0.01rem solid #838181;
        border-radius: 0.11rem;
    }
    .seamlessWarp{
		width: 4.5rem;
		height: 6.96rem;
		overflow: hidden;
	}
    .first{
		width: 0.16rem;
		height: 0.16rem;
		line-height: 0.18rem;
		text-align: center;
		border-radius: 50%;
		color: white;
		background-image: linear-gradient(to bottom , #FB8B2E ,#FEC554);
		margin: 0 auto;
	}
	.second{
		width: 0.16rem;
		height: 0.16rem;
		line-height: 0.18rem;
		text-align: center;
		border-radius: 50%;
		color: white;
		background-image: linear-gradient(to bottom , #FD6B87 ,#FF8FA5);
		margin: 0 auto;
	}
	.third{
		width: 0.16rem;
		height: 0.16rem;
		line-height: 0.18rem;
		text-align: center;
		border-radius: 50%;
		color: white;
		background-image: linear-gradient(to bottom , #0245D5 ,#00AEEB);
		margin: 0 auto;
	}
    #table{
		color: #7EA7E2;
		font-size: 0.14rem;
		text-align: left;
		width: 4.5rem;
        margin-top: 0.4rem;
        margin-left: 0.3rem;
		/*height: 6.96rem;*/
	}
	#table td{
		text-align: center;
		line-height: 0.3rem;
	}
</style>